/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/require-default-props */
/* eslint-disable no-plusplus */
import React from "react";
import { Link } from "gatsby";
import { v4 as uuidv4 } from "uuid";

import TitleTag from "./TitleTag";
import ListTag from "./ListTag";
import { formatSingleName } from "../../lib/functions";

interface CreateListProps {
  items: any;
  returnFieldName?: string;
  title?: string;
  showTitle?: boolean;
  noLink?: boolean;
  ddCategoryName?: string;
}

function CreateList(props: CreateListProps) {
  const { items, returnFieldName, showTitle, title, noLink, ddCategoryName } =
    props;
  if (!items) return null;

  try {
    // Ensure `items` is always an array
    const itemsArray = Array.isArray(items) ? items : [items];

    // Filter items based on label and status
    const regularItems = itemsArray.filter(
      (item: any) => item.label !== "Other" && item.status !== "REJECTED"
    );
    const otherItems = itemsArray.filter((item: any) => item.label === "Other");
    const rejectedItems = itemsArray.filter(
      (item: any) => item.status && item.status === "REJECTED"
    );

    const getItemLabel = (item: any) => {
      if (typeof item === "string") return item;
      const value = returnFieldName ? item[returnFieldName]?.label : item.label;
      return typeof value === "object" ? value["#text"] || "" : value;
    };

    // Generate the list of items
    const list = regularItems.map((item: any) => {
      let label = getItemLabel(item);

      if (title === "Writer of summary") {
        label = formatSingleName(label);
      }

      const parameter = ddCategoryName
        ? `q=*&s_${ddCategoryName}=${label}`
        : `q=${label}`;

      return label ? (
        <li key={uuidv4()}>
          {noLink ? (
            label
          ) : (
            <Link to={`/search/?${parameter}`} key={uuidv4()}>
              {label}
            </Link>
          )}
        </li>
      ) : null;
    });

    // Handle "Other" items
    if (otherItems.length > 0) {
      list.push(
        <>
          <li key={uuidv4()}>Other</li>
          <ul className="otherItem">
            {rejectedItems.map((item: any) => {
              if (
                item.label.indexOf(";") > -1 &&
                title === "First language of learners"
              ) {
                return item.label
                  .split(";")
                  .map((l: any) => <li key={uuidv4()}>{l}</li>);
              }
              return <li key={uuidv4()}>{item.label}</li>;
            })}
          </ul>
        </>
      );
    }

    return list.length > 0 ? (
      <div className="listView">
        {showTitle && <TitleTag>{title}</TitleTag>}
        <ListTag>{list}</ListTag>
        <br />
      </div>
    ) : null;
  } catch (error) {
    console.log("🚀 ~ CreateList ~ error:", error);
  }

  return null;
}

export default CreateList;
