/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import React, { useContext, useEffect, useState } from "react";
import H2 from "../htmlElements/h2";
import CreateList from "./CreateList";
import CreateText from "./CreateText";
import ReferencesForPublications from "./ReferencesForPublications";
import Acknowledgements from "./Acknowledgements";
import { DictionaryDataState } from "../../context/DictionaryDataContext";
import LoadingIcon from "../loadingIcon";

interface DetailsRightColumnContentsProps {
  materialMetadata: any;
}

export default function RightColumnContent(
  props: DetailsRightColumnContentsProps
) {
  const dictionaryDataState = useContext(DictionaryDataState) || [];

  const { materialMetadata } = props;

  const [loaded, setLoaded] = useState<boolean>(false);

  const [materialTypes, setMaterialTypes] = useState<object>([]);
  const [primaryMaterialType, setPrimaryMaterialType] = useState<object>([]);
  const [researchAreas, setResearchAreas] = useState<object>([]);
  const [participantTypes, setParticipantTypes] = useState<object>([]);
  const [dataTypes, setDataTypes] = useState<object>([]);
  const [linguisticFeatures, setLinguisticFeatures] = useState<object>([]);
  const [sourceOfFundings, setSourceOfFundings] = useState<object>([]);
  const [proficiencyOfLearners, setProficiencyOfLearners] = useState<object>(
    []
  );
  const [domainOfUses, setDomainOfUses] = useState<object>([]);

  /**
   * Filter our DD items
   * @param ddCategory string
   * @param ddItems array
   * @returns array of labels
   */
  interface DictionaryDataItem {
    type: string;
    value: string;
  }

  const getDDitem = (ddCategory: string, ddItems: any[]) => {
    try {
      if (ddItems.length > 0 && ddCategory !== "") {
        return ddItems
          .map((ddItem: DictionaryDataItem) => {
            if (ddItem.type === "DD") {
              if (dictionaryDataState[ddCategory]) {
                const filteredItems = dictionaryDataState[ddCategory].filter(
                  (ddCategoryItem: any) => ddCategoryItem.id === ddItem.value
                );
                if (
                  filteredItems &&
                  filteredItems[0] &&
                  filteredItems[0].label
                ) {
                  return filteredItems[0].label;
                }
              }
            } else if (ddItem.type.toLowerCase() === "new") {
              return ddItem.value;
            } else {
              return "";
            }
          })
          .filter((item: any) => item !== undefined);
      }
    } catch (error) {
      console.log("🚀 ~ getDDitem ~ error:", error);
      console.log("🚀 ~ getDDitem ~ ddCategory:", ddCategory);
      console.log("🚀 ~ getDDitem ~ ddItems:", ddItems);
    }

    return [];
  };

  /**
   * Get labels for each DD item
   */
  useEffect(() => {
    if (materialMetadata && dictionaryDataState) {
      const materialTypesArray = getDDitem("typeOfInstruments", materialMetadata.instrument.materialType); // eslint-disable-line prettier/prettier
      setMaterialTypes(materialTypesArray);

      const primaryMaterialTypeArray = getDDitem("typeOfInstruments", [materialMetadata.instrument.primaryMaterialType]); // eslint-disable-line prettier/prettier
      setPrimaryMaterialType(primaryMaterialTypeArray);

      const researchAreasArray = getDDitem("researchAreas", materialMetadata.instrument.researchArea); // eslint-disable-line prettier/prettier
      setResearchAreas(researchAreasArray);

      const participantTypesArray = getDDitem("participantTypes", materialMetadata.participant.type); // eslint-disable-line prettier/prettier
      setParticipantTypes(participantTypesArray);

      const dataTypesArray = getDDitem("dataTypes", materialMetadata.instrument.dataType); // eslint-disable-line prettier/prettier
      setDataTypes(dataTypesArray);

      const linguisticFeaturesArray = getDDitem("linguisticTargets", materialMetadata.instrument.linguisticFeature); // eslint-disable-line prettier/prettier
      setLinguisticFeatures(linguisticFeaturesArray);

      const sourceOfFundingsArray = getDDitem("funders", materialMetadata.instrument.sourceOfFunding); // eslint-disable-line prettier/prettier
      setSourceOfFundings(sourceOfFundingsArray);

      const proficiencyOfLearnersArray = getDDitem("proficiencies", materialMetadata.participant.proficiencyOfLearners); // eslint-disable-line prettier/prettier
      setProficiencyOfLearners(proficiencyOfLearnersArray);

      const domainOfUsesArray = getDDitem("domainOfUse", materialMetadata.participant.domainOfUse); // eslint-disable-line prettier/prettier
      setDomainOfUses(domainOfUsesArray);

      setLoaded(true);
    }
  }, [materialMetadata, dictionaryDataState]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Loading content
   */
  const loadingContent = (
    <div>
      <LoadingIcon />
    </div>
  );

  return loaded ? (
    <div className="border-0 pt-0">
      <div className="border-b border-b-gray-2 pb-5 mb-5">
        <H2 innerContent="About the material" additionalClasses="pb-5" />

        <CreateList
          title="Author(s)"
          items={materialMetadata.instrument.creator.map(
            (item: any) => item.fullName
          )}
          ddCategoryName="creators"
          returnFieldName="fullName"
          showTitle
        />

        <CreateList
          title="Type of Material"
          items={materialTypes}
          ddCategoryName="materialType"
          showTitle
        />

        <CreateList
          title="Primary Type of Material"
          items={primaryMaterialType}
          ddCategoryName="materialType"
          showTitle
        />

        <CreateList
          title="General Research Area(s)"
          items={researchAreas}
          ddCategoryName="researchArea"
          showTitle
        />

        <CreateList
          title="Reliability"
          items={materialMetadata.instrument.reliability}
          showTitle
        />
      </div>

      <ReferencesForPublications publications={materialMetadata.publication} />

      <div className="border-b border-b-gray-2 pb-5 mb-5">
        <H2 innerContent="More material details" additionalClasses="pb-5" />

        <CreateList
          title="Type of File"
          items={materialMetadata.instrument.typeOfFile}
          showTitle
        />

        <CreateList title="Data Type(s)" items={dataTypes} showTitle />

        <CreateList
          title="Linguistic feature"
          items={linguisticFeatures}
          showTitle
        />

        <CreateList
          title="Source(s) of Funding"
          items={sourceOfFundings}
          ddCategoryName="sourceOfFunding"
          showTitle
        />

        <CreateList
          title="Material Title or Name"
          items={materialMetadata.instrument.title}
          showTitle
        />

        <CreateList
          title="Language(s) in which the Instructions were Written"
          items={materialMetadata.instrument.language}
          ddCategoryName="language"
          showTitle
        />

        <CreateText
          title="Notes"
          value={materialMetadata.instrument.notes}
          showTitle
        />
      </div>

      <div className="border-b border-b-gray-2 pb-5 mb-5">
        <H2 innerContent="Participants in the study" additionalClasses="pb-5" />

        <CreateList
          title="Participant Type(s)"
          items={participantTypes}
          ddCategoryName="participantType"
          showTitle
        />

        <CreateList
          title="First Language of Learners"
          items={materialMetadata.participant.firstLanguage}
          ddCategoryName="firstLanguage"
          showTitle
        />

        <CreateList
          title="Language being used/learned"
          items={materialMetadata.participant.languageBeingLearned}
          ddCategoryName="languageBeingLearned"
          showTitle
        />

        <CreateList
          title="Ages of Learners"
          items={materialMetadata.participant.ageOfLearner}
          showTitle
        />

        <CreateList
          title="Gender of Learners"
          items={materialMetadata.participant.genderOfLearners}
          ddCategoryName="genderOfLearners"
          showTitle
        />

        <CreateList
          title="Proficiency of Learners"
          items={proficiencyOfLearners}
          ddCategoryName="proficiencyOfLearners"
          showTitle
        />

        <CreateList
          title="Length of Residence of Learners"
          items={materialMetadata.participant.lengthOfResidence}
          showTitle
        />

        <CreateList
          title="Age of Learners at Arrival"
          items={materialMetadata.participant.ageOfArrival}
          showTitle
        />

        <CreateList
          title="Age of Student"
          items={materialMetadata.participant.ageOfStudents}
          showTitle
        />

        <CreateList
          title="Amount of Prior Instruction"
          items={materialMetadata.participant.amountOfPriorInstruction}
          showTitle
        />

        <CreateList
          title="Amount of Instruction"
          items={materialMetadata.participant.amountOfInstruction}
          showTitle
        />

        <CreateList
          title="Time Spent Using the L2"
          items={materialMetadata.participant.timeSpent}
          showTitle
        />

        <CreateList
          title="Domains of use of language use/learning"
          items={domainOfUses}
          showTitle
        />
      </div>

      <Acknowledgements acknowledgement={materialMetadata.acknowledgement} />

      {/* <div className="border-b border-b-gray-2 pb-5 mb-5">
          <H2 innerContent="Other sections.." additionalClasses="pb-5 " />

          <div>Text</div>
        </div>

        <div className="border-b border-b-gray-2 pb-5 mb-5">
          <H2 innerContent="View feedback" additionalClasses="pb-5 " />

          <div>Text</div>
        </div> */}
    </div>
  ) : (
    loadingContent
  );
}
