/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import React, { useContext, useEffect, useState } from "react";
import { isArray, isEmpty } from "lodash";
import H2 from "../htmlElements/h2";
import CreateList from "./CreateList";
import CreateText from "./CreateText";
import ReferencesForPublications from "./ReferencesForPublications";
import { DictionaryDataState } from "../../context/DictionaryDataContext";
import LoadingIcon from "../loadingIcon";
import OasisVideo from "./oasisVideo";
import dictionaryDataHelpers from "../../helpers/dictionaryDataHelpers";
import { formatSingleName } from "../../lib/functions";

interface DetailsRightColumnContentsProps {
  materialMetadata: any;
  objectMetadata: any;
  filesMetadata?: any;
}

export default function RightColumnContentOasis(
  props: DetailsRightColumnContentsProps
) {
  const dictionaryDataState = useContext(DictionaryDataState) || [];

  const { materialMetadata, objectMetadata, filesMetadata } = props;

  const [loaded, setLoaded] = useState<boolean>(false);

  /* eslint-disable */
  const [summaryWriter, setSummaryWriter] = useState<object>([]);
  const [summaryLanguageSummaryWrittenIn, setSummaryLanguageSummaryWrittenIn] = useState<object>([]);
  const [summaryCollectionName, setSummaryCollectionName] = useState<any>([]);
  const [isSignLanguage, setIsSignLanguage] = useState<boolean>(false);
  const [publicationParticipantType, setPublicationParticipantType] = useState<object>([]);
  const [publicationGeneralResearchArea, setPublicationGeneralResearchArea] = useState<object>([]);
  const [publicationOfLikelyInterestTo, setPublicationOfLikelyInterestTo] = useState<object>([]);
  const [participantLinguisticTarget, setParticipantLinguisticTarget] = useState<object>([]);
  const [participantFirstLanguageOfLearners, setParticipantFirstLanguageOfLearners] = useState<object>([]);
  const [participantTargetLanguage, setParticipantTargetLanguage] = useState<object>([]);
  const [participantAgeOfLearners, setParticipantAgeOfLearners] = useState<object>([]);
  const [participantGender, setParticipantGender] = useState<object>([]);
  const [participantProficiencyOfLearners, setParticipantProficiencyOfLearners] = useState<object>([]);
  const [participantDomainOfUse, setParticipantDomainOfUse] = useState<object>([]);
  const [participantCountry, setParticipantCountry] = useState<object>([]);
  const [participantEducationalStage, setParticipantEducationalStage] = useState<object>([]);
  const [participantInstitutionalCharacteristics, setParticipantInstitutionalCharacteristics] = useState<object>([]);
  /* eslint-enable */

  const [uploadDate, setUploadDate] = useState<any>([]);
  const [works, setWorks] = useState<any>("");

  /**
   * Get labels for each DD item
   */
  useEffect(() => {
    if (!isEmpty(materialMetadata) && !isEmpty(dictionaryDataState)) {
      setSummaryWriter(
        dictionaryDataHelpers.getDDitem(
          dictionaryDataState,
          "oasisAuthors",
          materialMetadata.summary.summaryWriter
        )
      );

      setSummaryLanguageSummaryWrittenIn(
        dictionaryDataHelpers.getDDitem(
          dictionaryDataState,
          "oasisSummaryLanguageWrittenIn",
          materialMetadata.summary.summaryLanguageSummaryWrittenIn
        )
      );

      setPublicationParticipantType(
        dictionaryDataHelpers.getDDitem(
          dictionaryDataState,
          "oasisPublicationParticipantTypes",
          materialMetadata.publication[0].publicationParticipantType
        )
      );
      setPublicationGeneralResearchArea(
        dictionaryDataHelpers.getDDitem(
          dictionaryDataState,
          "oasisPublicationGeneralResearchAreas",
          materialMetadata.publication[0].publicationGeneralResearchArea
        )
      );
      setPublicationOfLikelyInterestTo(
        dictionaryDataHelpers.getDDitem(
          dictionaryDataState,
          "oasisPublicationOfLikelyInterestTo",
          materialMetadata.publication[0].publicationOfLikelyInterestTo
        )
      );

      setParticipantLinguisticTarget(
        dictionaryDataHelpers.getDDitem(
          dictionaryDataState,
          "oasisParticipantLinguisticTarget",
          materialMetadata.participant.participantLinguisticTarget
        )
      );
      setParticipantFirstLanguageOfLearners(
        dictionaryDataHelpers.getDDitem(
          dictionaryDataState,
          "oasisParticipantFirstLanguage",
          materialMetadata.participant.participantFirstLanguageOfLearners
        )
      );
      setParticipantTargetLanguage(
        dictionaryDataHelpers.getDDitem(
          dictionaryDataState,
          "oasisParticipantLanguagesBeingLearned",
          materialMetadata.participant.participantTargetLanguage
        )
      );
      setParticipantAgeOfLearners(
        dictionaryDataHelpers.getDDitem(
          dictionaryDataState,
          "oasisParticipantAgeOfLearners",
          materialMetadata.participant.participantAgeOfLearners
        )
      );
      setParticipantGender(
        dictionaryDataHelpers.getDDitem(
          dictionaryDataState,
          "oasisParticipantGender",
          materialMetadata.participant.participantGender
        )
      );
      setParticipantProficiencyOfLearners(
        dictionaryDataHelpers.getDDitem(
          dictionaryDataState,
          "oasisParticipantProficiencies",
          materialMetadata.participant.participantProficiencyOfLearners
        )
      );
      setParticipantDomainOfUse(
        dictionaryDataHelpers.getDDitem(
          dictionaryDataState,
          "oasisParticipantDomainOfUse",
          materialMetadata.participant.participantDomainOfUse
        )
      );
      setParticipantCountry(
        dictionaryDataHelpers.getDDitem(
          dictionaryDataState,
          "oasisParticipantCountry",
          materialMetadata.participant.participantCountry
        )
      );
      setParticipantEducationalStage(
        dictionaryDataHelpers.getDDitem(
          dictionaryDataState,
          "oasisParticipantEducationalStage",
          materialMetadata.participant.participantEducationalStage
        )
      );
      setParticipantInstitutionalCharacteristics(
        dictionaryDataHelpers.getDDitem(
          dictionaryDataState,
          "oasisParticipantInstitutionalCharacteristics",
          materialMetadata.participant.participantInstitutionalCharacteristics
        )
      );
      setSummaryCollectionName(
        dictionaryDataHelpers.getDDitem(
          dictionaryDataState,
          "oasisSummaryCollectionName",
          materialMetadata.summary.summaryCollectionName
        )
      );

      // Upload date
      if (objectMetadata.createdAt) {
        const date = new Date(objectMetadata.createdAt);
        const options: any = { day: "2-digit", month: "long", year: "numeric" };
        const formattedDate = date.toLocaleDateString("en-GB", options);
        setUploadDate(formattedDate);
      }

      if (
        materialMetadata.publication &&
        materialMetadata.publication.length > 0
      ) {
        const publications = materialMetadata.publication.map(
          (publication: any) => {
            return {
              ...publication,
              publicationType: "OASIS Summary Title",
            };
          }
        );

        setWorks(publications);
      }

      setLoaded(true);
    }
  }, [materialMetadata, dictionaryDataState]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Special case, check is materual is sign language - if yes show video
   */
  useEffect(() => {
    const targetLanguages = ["Sign Language (other)", "American Sign Language"];

    if (!isEmpty(summaryLanguageSummaryWrittenIn) && isArray(summaryLanguageSummaryWrittenIn)) { // eslint-disable-line prettier/prettier
      if (
        summaryLanguageSummaryWrittenIn.some((item: any) =>
          targetLanguages.includes(item.label)
        )
      ) {
        setIsSignLanguage(true);
      }
    }
  }, [summaryLanguageSummaryWrittenIn]);

  /**
   * Loading content
   */
  const loadingContent = (
    <div>
      <LoadingIcon />
    </div>
  );

  return loaded ? (
    <div className="border-0 pt-0">
      <div className="border-b border-b-gray-2 mb-5">
        <H2 innerContent="Information about summary" additionalClasses="pb-5" />

        <CreateList
          title="Writer of summary"
          items={summaryWriter}
          ddCategoryName="summaryWriter"
          showTitle
        />

        <CreateList title="Upload date" items={uploadDate} showTitle noLink />

        <CreateList
          title="Area of research"
          items={publicationGeneralResearchArea}
          ddCategoryName="publicationGeneralResearchArea"
          showTitle
        />

        <CreateList
          title="Language summary written in"
          items={summaryLanguageSummaryWrittenIn}
          ddCategoryName="summaryLanguageSummaryWrittenIn"
          showTitle
        />

        {materialMetadata.summary.summaryMaterialsOnIris === "Yes" &&
        materialMetadata.publication.length > 0 &&
        materialMetadata.publication[0].publicationIrisUrls.length > 0 ? (
          <>
            <img
              className="h-12 inline float-left mr-2"
              src="/images/iris_logo_small.jpg"
              alt="OASIS Logo"
            />
            <CreateText
              title="Materials on IRIS"
              value={
                <a
                  href={materialMetadata.publication[0].publicationIrisUrls[0]}
                  target="_blank"
                  rel="noreferrer"
                >
                  {materialMetadata.publication[0].publicationIrisUrls[0]}
                </a>
              }
              showTitle
            />
          </>
        ) : null}
      </div>

      <ReferencesForPublications
        publications={works}
        title="Original publication"
      />

      <div className="border-b border-b-gray-2 mb-5">
        <H2 innerContent="Participants in the study" additionalClasses="pb-5" />

        <CreateList
          title="Participant type"
          items={publicationParticipantType}
          ddCategoryName="publicationParticipantType"
          showTitle
        />

        <CreateList
          title="Language being learned"
          items={participantTargetLanguage}
          ddCategoryName="participantTargetLanguage"
          showTitle
        />

        <CreateList
          title="Age of learners"
          items={participantAgeOfLearners}
          ddCategoryName="participantAgeOfLearners"
          showTitle
        />

        {/* <CreateList
          title="Of likely interest to ..."
          items={publicationOfLikelyInterestTo}
          ddCategoryName="publicationOfLikelyInterestTo"
          showTitle
        /> */}

        {materialMetadata.settings.settingsNotes ? (
          <CreateText
            title="Notes"
            value={materialMetadata.settings.settingsNotes}
            showTitle
          />
        ) : null}

        <CreateList
          title="First language of learners"
          items={participantFirstLanguageOfLearners}
          ddCategoryName="participantFirstLanguageOfLearners"
          showTitle
        />

        {materialMetadata.participant.participantLengthOfResidence ? (
          <CreateText
            title="Length of residence of learners"
            value={materialMetadata.participant.participantLengthOfResidence}
            showTitle
          />
        ) : null}

        {materialMetadata.participant.participantAgeOfLearnersOfArrival ? (
          <CreateText
            title="Age of learners at arrival"
            value={
              materialMetadata.participant.participantAgeOfLearnersOfArrival
            }
            showTitle
          />
        ) : null}

        <CreateList
          title="Context of language use"
          items={participantDomainOfUse}
          ddCategoryName="participantDomainOfUse"
          showTitle
        />

        <CreateList
          title="Proficiency of learners"
          items={participantProficiencyOfLearners}
          ddCategoryName="participantProficiencyOfLearners"
          showTitle
        />

        <CreateList
          title="Gender of learners"
          items={participantGender}
          ddCategoryName="participantGender"
          showTitle
        />

        {materialMetadata.participant.participantTimeSpentUsingL2 ? (
          <CreateText
            title="Time spent using the L2"
            value={materialMetadata.participant.participantTimeSpentUsingL2}
            showTitle
          />
        ) : null}

        {materialMetadata.participant.participantYearOfTeachingExperience ? (
          <CreateText
            title="Years of teaching experience"
            value={
              materialMetadata.participant.participantYearOfTeachingExperience
            }
            showTitle
          />
        ) : null}

        <CreateList
          title="Country / region"
          items={participantCountry}
          ddCategoryName="participantCountry"
          showTitle
        />

        {materialMetadata.participant.participantSubnationalRegion ? (
          <CreateText
            title="Subnational region"
            value={materialMetadata.participant.participantSubnationalRegion}
            showTitle
          />
        ) : null}

        <CreateList
          title="Educational stage"
          items={participantEducationalStage}
          ddCategoryName="participantEducationalStage"
          showTitle
        />

        <CreateList
          title="Institutional characteristics"
          items={participantInstitutionalCharacteristics}
          ddCategoryName="participantInstitutionalCharacteristics"
          showTitle
        />

        {materialMetadata.participant.participantAmountOfPriorInstruction ? (
          <CreateText
            title="Amount of prior instruction"
            value={
              materialMetadata.participant.participantAmountOfPriorInstruction
            }
            showTitle
          />
        ) : null}

        <CreateList
          title="Feature being learned"
          items={participantLinguisticTarget}
          ddCategoryName="participantLinguisticTarget"
          showTitle
        />

        {materialMetadata.participant.participantAmountOfInstruction ? (
          <CreateText
            title="Amount of instruction"
            value={materialMetadata.participant.participantAmountOfInstruction}
            showTitle
          />
        ) : null}

        {materialMetadata.participant.participantTypeOfInstruction ? (
          <CreateList
            title="Type of instruction"
            items={materialMetadata.participant.participantTypeOfInstruction}
            ddCategoryName="participantTypeOfInstruction"
            showTitle
            noLink
          />
        ) : null}
      </div>

      {isSignLanguage && filesMetadata ? (
        <OasisVideo filesMetadata={filesMetadata} />
      ) : null}

      {summaryCollectionName && summaryCollectionName.length > 0 ? (
        <div className="border-b border-b-gray-2 mb-5 pb-5">
          <H2 innerContent="Collection" additionalClasses="pb-5" />
          <a
            href={summaryCollectionName[0].def}
            target="_blank"
            rel="noreferrer"
          >
            {summaryCollectionName[0].label}
          </a>{" "}
          {/* <img
            src="../images/new-window-icon.jpg"
            width={15}
            style={{ display: `inline-block`, marginLeft: `5px` }}
            alt="Opens the link in a new windows"
          /> */}
        </div>
      ) : null}

      <div className="">
        <H2 innerContent="Licence" additionalClasses="pb-5" />

        <CreateText
          value={
            <a
              href="https://creativecommons.org/licenses/by-nc-sa/4.0/"
              target="_blank"
              rel="noreferrer"
            >
              Attribution-NonCommercial-ShareAlike 4.0 International (CC
              BY-NC-SA 4.0)
            </a>
          }
          showTitle
        />
      </div>
    </div>
  ) : (
    loadingContent
  );
}

RightColumnContentOasis.defaultProps = {
  filesMetadata: [],
};
